import { useEffect } from 'react'
import useSWR from 'swr'

import type { AbConfig } from 'api/ab.api'
import { logDebug } from 'infrastructure/logger'

export function useAbConfig(initialAbConfig?: AbConfig) {
	const { data } = useSWR<AbConfig>(initialAbConfig ? null : '/ab', {
		fallbackData: initialAbConfig,
		revalidateOnMount: true,
		revalidateOnFocus: false,
		revalidateOnReconnect: false,
	})

	useEffect(() => {
		logDebug('Client-side AbConfig', data)
	}, [data])

	return data
}
