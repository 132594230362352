import { createRoot } from 'react-dom/client'

import type { Methods } from './ToastPortal'
import { ToastPortal } from './ToastPortal'
import type { ToastId, ToastMessage, ToastProps } from './toast.types'

const portalId = '__notifications'

class Toaster {
	private createToast?: Methods['notify']
	private removeAll?: Methods['closeAll']
	private closeToast?: Methods['close']
	private isToastActive?: Methods['isActive']

	constructor() {
		if (typeof window === 'undefined') {
			return
		}

		let portal: HTMLElement
		const existingPortal = document.getElementById(portalId)

		if (existingPortal) {
			portal = existingPortal
		} else {
			const div = document.createElement('div')

			div.id = portalId
			document.body?.appendChild(div)
			portal = div
		}

		createRoot(portal).render(<ToastPortal notify={this.bindFunctions} />)
	}

	private bindFunctions = (methods: Methods) => {
		this.createToast = methods.notify
		this.removeAll = methods.closeAll
		this.closeToast = methods.close
		this.isToastActive = methods.isActive
	}

	notify = (message: ToastMessage, options: ToastProps) => {
		return this.createToast?.(message, options)
	}

	close = (id: ToastId) => {
		this.closeToast?.(id)
	}

	closeAll = () => {
		this.removeAll?.()
	}

	isActive = (id: ToastId) => {
		return this.isToastActive?.(id)
	}
}

export const toast = new Toaster()
