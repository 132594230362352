import type { User } from 'api/user.d'
import { isBlocked as isFrontappChatBlocked } from 'hooks/use-frontapp-chat'
import { SiteRoutes } from 'libs/routes'
import type { NextPageLayout } from 'typings/next'

export enum SUPPORT_METHOD {
	'none',
	'phone',
	'chat',
}

type Args = { user?: User | null; layout: NextPageLayout; pathname: string }

export function getSupportMethod({ user, layout, pathname }: Args): SUPPORT_METHOD {
	const layoutPhone = layout.showPhone === false ? SUPPORT_METHOD.none : SUPPORT_METHOD.phone

	if (user === undefined || pathname === SiteRoutes.SIGNOUT()) {
		return SUPPORT_METHOD.none
	}

	if (user === null) {
		return layoutPhone
	}

	return user && user.showChat && !isFrontappChatBlocked() ? SUPPORT_METHOD.chat : layoutPhone
}
