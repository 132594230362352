'use client'

import clsx from 'clsx'
import Image from 'next/image'
import { usePathname } from 'next/navigation'
import { useState, useMemo } from 'react'

import type { Reservation } from 'api/reservations.d'
import { ArrowRound } from 'components/ArrowRound'
import { AsyncIcon } from 'components/AsyncIcon'
import { Button } from 'components/Button'
import { Link } from 'components/Link'
import { Logo } from 'components/Logo'
import { NoSSR } from 'components/NoSSR'
import { usePreApprove } from 'components/PreApprove/PreApprove.hooks'
import { useAppContext } from 'context'
import { Header as HeaderFragment, HeaderItem } from 'fragments/Header'
import { MobileMenu } from 'fragments/Header/MobileMenu'
import { useGoogleIdentifyPrompt } from 'hooks/use-google-identify-prompt'
import { useMedia } from 'hooks/use-media'
import { useUser } from 'hooks/use-user'
import { MEDIA_QUERY } from 'libs/consts'
import { SiteRoutes } from 'libs/routes'
import { Typography } from 'ui/components/Typography/v2'
import { Events, setAnalyticsData } from 'utils/analytics'
import { extractCityFromPath } from 'utils/extract-city-from-path'
import { findCityBySlug } from 'utils/find-city-by-slug'
import { ReservationsList } from 'views/Journey/features/ReservationsList.app'

import { Cities } from './Cities'
import styles from './Header.module.css'
import { shouldShowMyBookings } from './Header.utils'

export function Header() {
	const { cities } = useAppContext()
	const pathname = usePathname()
	const isMobile = useMedia([MEDIA_QUERY.smDown], [true], false)
	const { user, isValidating } = useUser()
	const { showPreapprove, nextPreapprove, showContinuePreapproval, continuePreapprove } = usePreApprove()
	const [reservations, setReservations] = useState<Reservation[] | null>(null)
	useGoogleIdentifyPrompt(user, isValidating)
	const username = user ? user.firstName ?? user.lastName ?? user.email : null
	const hasActiveReservation = reservations?.some((val) => ['active', 'upcoming'].includes(val.state))
	const hasReservationInProgress = Boolean(reservations?.some((val) => ['in_progress'].includes(val.state)))
	const hasMonthlyPayment = reservations?.some((val) => ['active', 'upcoming', 'archived'].includes(val.state))
	const showBookingNotification = Boolean(reservations?.some((val) => ['in_progress'].includes(val.state) && val.room))
	const showMyBookings = shouldShowMyBookings(reservations, hasReservationInProgress)
	const showPaymentsPortal = hasMonthlyPayment && Boolean(reservations?.find((r) => r.paymentPortalAccess))
	const cityCode = useMemo(() => {
		const slug = extractCityFromPath(String(pathname))
		if (!slug) {
			return ''
		}
		const city = findCityBySlug(slug, cities)
		return city?.cityCode ?? ''
	}, [cities, pathname])

	function handleSetReservationsCount(reservations: Reservation[]) {
		setReservations(reservations)
	}

	return (
		<HeaderFragment pathname={String(pathname)}>
			<HeaderItem
				key="location"
				className={styles.location}
				dropdown={() => (
					<MobileMenu>
						<ul className={styles.listMobile}>
							<Cities cities={cities} />
						</ul>
					</MobileMenu>
				)}
			>
				<div className={clsx(styles.cities, cityCode && styles.citiesUpper)}>
					<AsyncIcon key="burger-icon" className={styles.locationIcon} src={require('./assets/icons/location.svg')} />
					<Typography as="span" variant="p1-500">
						{cityCode || 'Cities'}
					</Typography>
				</div>
			</HeaderItem>

			<HeaderItem
				key="all-homes"
				className={styles.allhomesItem}
				href={SiteRoutes.CATALOG_WITH_DEFAULT_CITY()}
				fullwidth
				dropdown={() => (
					<NoSSR>
						<div className={styles.desktopCities}>
							<span className={styles.headerCities}>Trending Cities</span>
							<Cities cities={cities} />
						</div>
					</NoSSR>
				)}
			>
				<div className={clsx(styles.cities, cityCode && styles.citiesUpper)}>
					<AsyncIcon className={styles.locationIcon} src={require('./assets/icons/location.svg')} />
					<Typography as="span" variant="p1-500">
						{cityCode || 'Cities'}
					</Typography>
					<span className={styles.arrow}>
						<ArrowRound size="14px" />
					</span>
				</div>
			</HeaderItem>

			<HeaderItem
				key="user"
				className={styles.userItem}
				dropdownPosition="left"
				dropdown={() => (
					<ul className={styles.list}>
						<li className={styles.item}>
							<Link href={SiteRoutes.ABOUT_US()}>
								<div className={styles.link} data-close="click">
									Company
									<div className={styles.arrow}>
										<ArrowRound size="12px" />
									</div>
								</div>
							</Link>
						</li>
						<li className={styles.item}>
							<Link href={SiteRoutes.HOW_IT_WORKS()}>
								<div className={styles.link} data-close="click">
									How it Works
									<div className={styles.arrow}>
										<ArrowRound size="12px" />
									</div>
								</div>
							</Link>
						</li>
						<li className={styles.item}>
							<Link href={SiteRoutes.FAQ()}>
								<div className={styles.link} data-close="click">
									FAQ
									<div className={styles.arrow}>
										<ArrowRound size="12px" />
									</div>
								</div>
							</Link>
						</li>
						<li className={styles.item}>
							<Link href={SiteRoutes.CAREERS()}>
								<div className={styles.link} data-close="click">
									Careers
									<div className={styles.arrow}>
										<ArrowRound size="12px" />
									</div>
								</div>
							</Link>
						</li>
					</ul>
				)}
			>
				<>
					About
					<span className={styles.arrow}>
						<ArrowRound size="14px" />
					</span>
				</>
			</HeaderItem>

			<HeaderItem key="property-owners" href={SiteRoutes.FOR_OWNERS()} className={styles.ownersItem}>
				Property Owners
			</HeaderItem>

			<div className={styles.spacer}></div>

			<HeaderItem
				key="logo"
				href={SiteRoutes.ROOT()}
				className={clsx(styles.logoItem, user && styles.logoItemLogged)}
				applyActiveStyles={false}
			>
				<div className={styles.logo}>
					<Logo type="homes" withText={false} />
				</div>
			</HeaderItem>
			{showPreapprove && (
				<HeaderItem key="preapprove" className={styles.signinItem}>
					<div className={styles.buttonLaptop}>
						<Button
							onClick={() => nextPreapprove()}
							kind="button"
							form="rounded"
							color="white"
							data-click={setAnalyticsData({
								name: Events.PreApproval.MAIN,
								params: { action_to: 'header' },
							})}
						>
							Get Pre-approved
						</Button>
					</div>
				</HeaderItem>
			)}
			{showContinuePreapproval && (
				<HeaderItem key="continue-preapprove" className={styles.signinItem}>
					<div className={styles.buttonLaptop}>
						<Button
							onClick={() => continuePreapprove()}
							kind="button"
							form="rounded"
							color="white"
							data-click={setAnalyticsData({
								name: Events.PreApproval.CONTINUE,
								params: { action_to: 'header' },
							})}
						>
							Continue Pre-approval
						</Button>
					</div>
				</HeaderItem>
			)}
			{user ? (
				<NoSSR>
					<HeaderItem
						key="reservations"
						className={clsx(styles.reservationsItem, !showMyBookings && styles.notVisible)}
						dropdownPosition="right"
						dropdown={({ close }) => (
							<MobileMenu active={isMobile}>
								<ul
									className={clsx({
										[styles.listMobile]: isMobile,
										[styles.list]: !isMobile,
									})}
								>
									<li className={styles.reservations}>
										<ReservationsList onSetReservations={handleSetReservationsCount} onSetCurrent={close} />
									</li>
								</ul>
							</MobileMenu>
						)}
					>
						<div className={clsx(styles.reservationsButton, showBookingNotification && styles.notification)}>
							<AsyncIcon src={require('./assets/icons/schedule.svg')} className={styles.scheduleIcon} />
							{!isMobile && 'My Bookings'}
						</div>
					</HeaderItem>

					<HeaderItem key="favorites" href={SiteRoutes.FAVORITES()}>
						<div className={styles.favorites}>
							<AsyncIcon className={styles.favoritesIcon} src={require('assets/icons/heart.svg')} />
							<span className={styles.favoritesTitle}>Favorites</span>
						</div>
					</HeaderItem>
					<HeaderItem
						key="profile"
						className={styles.mobileUserItem}
						dropdown={() => (
							<MobileMenu>
								<ul className={styles.listMobile}>
									<li key="Resident_Portal" className={styles.itemMobile}>
										<Link
											href={SiteRoutes.MEMBER_PORTAL()}
											className={clsx(styles.link, !hasActiveReservation && styles.disabledLink)}
										>
											Resident Portal
										</Link>
										<ArrowRound size="18px" rotate={270} />
									</li>

									{showPaymentsPortal && (
										<li key="Rent_Payments" className={styles.itemMobile}>
											<Link href={SiteRoutes.PAYMENT_PORTAL()} className={styles.link}>
												Rent Payments
											</Link>
											<ArrowRound size="18px" rotate={270} />
										</li>
									)}

									<li key="Support_Center" className={styles.itemMobile}>
										<Link
											href={SiteRoutes.SUPPORT()}
											className={clsx(styles.link, !hasActiveReservation && styles.disabledLink)}
										>
											Support Center
										</Link>
										<ArrowRound size="18px" rotate={270} />
									</li>

									<li key="Settings" className={styles.itemMobile}>
										<Link href={SiteRoutes.SETTINGS()} className={styles.link}>
											Settings
										</Link>
										<ArrowRound size="18px" rotate={270} />
									</li>
									<li key="Logout" className={styles.itemMobile}>
										<Link href={SiteRoutes.SIGNOUT()} className={styles.link}>
											Logout
										</Link>
										<ArrowRound size="18px" rotate={270} />
									</li>
								</ul>
							</MobileMenu>
						)}
					>
						<AsyncIcon className={styles.mobileUserIcon} src={require('./assets/icons/user.svg')} />
					</HeaderItem>

					<HeaderItem
						key="user"
						className={styles.userItem}
						dropdownPosition="right"
						dropdown={() => (
							<ul className={styles.list}>
								<li className={styles.item}>
									<Link
										href={SiteRoutes.MEMBER_PORTAL()}
										className={clsx(styles.link, !hasActiveReservation && styles.disabledLink)}
									>
										Resident Portal
									</Link>
								</li>
								{showPaymentsPortal && (
									<li className={styles.item}>
										<Link href={SiteRoutes.MONTHLY_PAYMENT({})} className={styles.link}>
											Rent Payments
										</Link>
									</li>
								)}
								<li className={styles.item}>
									<Link
										href={SiteRoutes.SUPPORT()}
										className={clsx(styles.link, !hasActiveReservation && styles.disabledLink)}
									>
										Support Center
									</Link>
								</li>
								<li className={styles.item}>
									<Link href={SiteRoutes.SETTINGS()} className={styles.link}>
										Settings
									</Link>
								</li>
								<li className={styles.item}>
									<Link className={styles.link} href={SiteRoutes.SIGNOUT()}>
										Logout
									</Link>
								</li>
							</ul>
						)}
					>
						<div className={styles.user}>
							<div className={styles.userPhoto}>
								<Image src={user.avatarUrl as string} objectFit="cover" width="22" height="22" alt={username || ''} />
							</div>
							<span className={styles.userName}>{username}</span>
							<div className={styles.userArrow}>
								<ArrowRound size="12px" />
							</div>
						</div>
					</HeaderItem>
				</NoSSR>
			) : (
				<>
					<HeaderItem
						key="userLogin"
						className={styles.userSignIn}
						dropdownPosition="right"
						dropdown={() => (
							<ul className={styles.list}>
								<li className={styles.item}>
									<Link href={SiteRoutes.SIGNIN()}>
										<div className={styles.link} data-close="click">
											Sign In
											<div className={styles.arrow}>
												<ArrowRound size="12px" />
											</div>
										</div>
									</Link>
								</li>
								<li className={styles.item}>
									<Link href={SiteRoutes.SIGNUP({ next: String(pathname) })}>
										<div className={styles.link} data-close="click">
											Sign Up
											<div className={styles.arrow}>
												<ArrowRound size="12px" />
											</div>
										</div>
									</Link>
								</li>
							</ul>
						)}
					>
						<div className={styles.user}>
							<AsyncIcon className={styles.userIcon} src={require('./assets/icons/user.svg')} />
							<div className={styles.userArrow}>
								<ArrowRound size="12px" />
							</div>
						</div>
					</HeaderItem>

					<HeaderItem key="signin" href={SiteRoutes.SIGNIN()} className={styles.signinItem}>
						<div className={styles.buttonDesktop}>
							<Button kind="button" form="rounded" color="white">
								Login
							</Button>
						</div>
					</HeaderItem>
					<HeaderItem key="signup" href={SiteRoutes.SIGNUP({ next: String(pathname) })} className={styles.signupItem}>
						<Button kind="button" form="rounded" color="cobalt">
							Sign Up
						</Button>
					</HeaderItem>
				</>
			)}
		</HeaderFragment>
	)
}
