import clsx from 'clsx'
import { useMemo } from 'react'

import { AsyncIcon } from 'components/AsyncIcon'
import type { AppContextState } from 'context'
import { SiteRoutes } from 'libs/routes'
import { Typography } from 'ui/components/Typography'
import { Typography as TypographyV2 } from 'ui/components/Typography/v2'

import s from './Footer.module.css'
import { Contacts } from './components/Contacts'
import { NavigationList } from './components/NavigationList'
import logo from './logo.svg'
import type { CityLink, CitySlug } from './navigationLists'
import {
	companyList,
	policyList,
	housingList,
	specificCityLinks,
	citySlugToTitleMapping,
	OTHER_CITY_SLUG,
} from './navigationLists'

type GroupedCityLinksType = {
	[key in CitySlug]: CityLink[]
}

export type FooterProps = { className?: string; cities: AppContextState['cities']; marginTop?: boolean }

export const Footer = ({ className, cities, marginTop = true }: FooterProps) => {
	const groupedCities = useMemo((): GroupedCityLinksType => {
		const initialCityGroups = {
			'new-york-city-ny': [],
			'washington-dc': [],
			'boston-ma': [],
			'chicago': [],
			[OTHER_CITY_SLUG]: [],
		} as GroupedCityLinksType

		cities.forEach((city) => {
			if (!specificCityLinks[city.slug as CitySlug]) {
				initialCityGroups[OTHER_CITY_SLUG].push({
					text: city.name,
					href: SiteRoutes.CATALOG_WITH_CITY({ city: city.slug }),
				})
			}
		})

		return initialCityGroups
	}, [cities])

	return (
		<>
			<div className={s.divider} />
			<footer className={clsx(s.root, { [s.marginTop]: marginTop }, className)}>
				<div className={s.container}>
					<div className={s.contacts}>
						<div className={s.logo}>
							<AsyncIcon className={clsx(className, s.juneLogo)} src={logo} />
							<TypographyV2 as="p" color="charcoal-800" variant="h5-500">
								June Homes provides apartments and&nbsp;rooms for rent in major US cities
								on&nbsp;flexible&nbsp;lease&nbsp;terms.
							</TypographyV2>
						</div>
						<Contacts />
					</div>
					<div className={s.mobileDivider} />
					<div className={s.cols}>
						{Object.entries(groupedCities).map(([slug, links]) => (
							<NavigationList
								key={slug}
								title={citySlugToTitleMapping[slug as CitySlug]}
								list={[...(specificCityLinks[slug as CitySlug] || []), ...links]}
							/>
						))}
						<NavigationList title="Housing" list={housingList} />
						<NavigationList title="Company" list={companyList} />
						<NavigationList className={s.policyList} title="Other" list={policyList} />
					</div>
				</div>
				<div className={s.divider} />
				<Typography className={s.copyright} variant="bold" as="span">
					© {new Date().getFullYear()} June Homes. Rentals Redefined
				</Typography>
			</footer>
		</>
	)
}
