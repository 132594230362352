import clsx from 'clsx'
import Image from 'next/image'

import { AsyncIcon } from 'components/AsyncIcon'

import styles from './PhoneButton.module.css'

type Props = {
	phoneNumber?: string
	light?: boolean
}

export function PhoneButton({ light, phoneNumber }: Props) {
	if (!phoneNumber) {
		return null
	}

	return (
		<a className={clsx(styles.root, light && styles.rootLight)} href={`tel:${phoneNumber}`}>
			<div className={styles.avatar}>
				<Image src={require('./assets/the-agent.png')} width={56} height={56} alt="Manager" />
			</div>
			<AsyncIcon
				src={require('assets/icons/phone-filled.svg')}
				className={clsx(styles.icon, light && styles.iconLight)}
			/>
			<div className={styles.content}>
				Do you have any questions?
				<div className={styles.phone}>{phoneNumber}</div>
			</div>
		</a>
	)
}
