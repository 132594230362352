import { useEffect } from 'react'

import type { User } from 'api/user.d'
import { useUser } from 'hooks/use-user'
import { GoogleAuthConfig, STORAGE_KEYS } from 'libs/consts'

const GOOGLE_ONE_TAP_DIV_ID = 'g_id_onload'
const GOOGLE_ONE_TAP_IFRAME_ID = 'credential_picker_container'

export function useGoogleOneTap(initialData?: User | null | undefined) {
	const { user } = useUser(initialData)

	useEffect(() => {
		if (user) return

		const city = typeof window !== 'undefined' ? localStorage.getItem(STORAGE_KEYS.currentCity) : null

		const queryParams = new URLSearchParams()

		if (city) {
			queryParams.set('city', city)
		}

		const nextUri = `${new URL(GoogleAuthConfig.LOGIN_CALLBACK_PATH, process.env.NEXT_PUBLIC_BASE_URL).toString()}${
			GoogleAuthConfig.QUERY_PARAM
		}&oneTap=true`

		queryParams.set('next', nextUri)

		const loginUri = `${GoogleAuthConfig.REDIRECT_URI}?${queryParams.toString()}`

		const gIdDiv = document.createElement('div')
		gIdDiv.id = GOOGLE_ONE_TAP_DIV_ID
		gIdDiv.setAttribute('data-client_id', `${process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}`)
		gIdDiv.setAttribute('data-context', 'signin')
		gIdDiv.setAttribute('data-login_uri', loginUri)
		gIdDiv.setAttribute('data-itp_support', 'true')
		gIdDiv.setAttribute('data-auto_prompt', 'false')
		document.body.appendChild(gIdDiv)

		return () => {
			document.body.removeChild(gIdDiv)
		}
	}, [user])

	useEffect(() => {
		if (!user) return

		const gIdDiv = document.getElementById(GOOGLE_ONE_TAP_DIV_ID)
		const gIdIframe = document.getElementById(GOOGLE_ONE_TAP_IFRAME_ID)

		if (gIdDiv) {
			document.body.removeChild(gIdDiv)
		}

		if (gIdIframe) {
			document.body.removeChild(gIdIframe)
		}
	}, [user])
}
