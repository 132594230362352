'use client'

import { usePathname } from 'next/navigation'

import type { User } from 'api/user.d'
import { getSupportMethod, SUPPORT_METHOD } from 'features/App/get-support-method'
import { useUser } from 'hooks/use-user'
import type { NextPageLayout } from 'typings/next'

import { RenderChat } from './RenderChat'

type Props = {
	initialUserData?: User | null
	layout: NextPageLayout
}

export function FrontappChat({ initialUserData, layout }: Props) {
	const pathname = usePathname()
	const { user } = useUser(initialUserData)
	const supportMethod = getSupportMethod({ user, layout, pathname: String(pathname) })

	return supportMethod === SUPPORT_METHOD.chat ? <RenderChat user={user} /> : null
}
