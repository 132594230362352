'use client'

import { useRef, useEffect } from 'react'

import { useAbContext } from 'context'

import { useAbConfig } from './useAbConfig'

export function AbConfigHydration() {
	const [config, setConfig] = useAbContext((config) => config)
	const initialAbConfig = useRef(config)
	const abConfig = useAbConfig(initialAbConfig.current ?? undefined)

	useEffect(() => {
		if (abConfig) {
			setConfig(abConfig)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [abConfig])

	return null
}
