import clsx from 'clsx'
import { forwardRef } from 'react'

import styles from './Button.module.css'

export type Props = Omit<React.AllHTMLAttributes<HTMLButtonElement | HTMLAnchorElement | HTMLElement>, 'as'> & {
	as?: React.ElementType
	color?: 'action' | 'sark' | 'black' | 'white' | 'orange' | 'lunar' | 'cobalt'
	kind?: 'button' | 'link' | 'flat' | 'shallow'
	loading?: boolean
	form?: 'squired' | 'rounded'
	fill?: boolean
	spinnerClassname?: string
}

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement | HTMLElement, Props>(function Button(
	{
		as: Component = 'button',
		children,
		loading,
		color = 'action',
		form,
		type = 'button',
		kind = 'button',
		fill,
		className,
		spinnerClassname,
		...props
	},
	ref
) {
	return (
		<Component
			{...props}
			className={clsx(
				styles.root,
				styles[color],
				styles[kind],
				form && styles[form],
				{
					[styles.link]: kind === 'flat',
					[styles.loading]: loading,
					[styles.fill]: fill,
				},
				className
			)}
			ref={ref}
			type={Component === 'button' ? type : undefined}
		>
			{loading && <div className={clsx(styles.spinner, spinnerClassname)} />}
			<span className={styles.content}>{children}</span>
		</Component>
	)
})
