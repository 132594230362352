import type { IncomingMessage } from 'http'

import { extractCityFromPath } from 'utils/extract-city-from-path'

import { STORAGE_KEYS } from '../libs/consts'

export function getCityFromLocalStorage(): string | null {
	return localStorage.getItem(STORAGE_KEYS.currentCity)
}

export function getCityFromRequest(req: IncomingMessage): string | null {
	const url = req.url

	if (!url) return null

	return extractCityFromPath(url)
}
