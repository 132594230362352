import clsx from 'clsx'
import { useState, useEffect } from 'react'

import { AsyncIcon } from 'components/AsyncIcon'
import { SelectBox } from 'components/SelectBox'
import type { City } from 'server/data/initial-data.types'
import type { DataAnalytics } from 'utils/analytics'

import styles from './CityPicker.module.css'

type Props = {
	className?: string
	cities: City[]
	defaultValue?: string
	onPick(city: string, evt: React.FormEvent<HTMLElement>): Promise<void> | void
	twoColumns?: boolean
} & DataAnalytics

export function CityPicker({ cities, defaultValue, twoColumns = false, onPick, ...props }: Props) {
	const [selected, setSelected] = useState(defaultValue)

	async function handlePick(evt: React.ChangeEvent<HTMLInputElement>) {
		const { value } = evt.currentTarget

		setSelected(value)

		await onPick(value, evt)
	}

	useEffect(() => {
		setSelected(defaultValue)
	}, [defaultValue])

	return (
		<div className={styles.root}>
			{cities.map(({ slug, name, comingSoon, icon }) => {
				const active = slug === selected

				return (
					<div
						key={slug}
						className={clsx(styles.item, {
							[styles.itemHalf]: twoColumns,
						})}
					>
						<SelectBox
							type="radio"
							name="city"
							value={slug}
							onChange={handlePick}
							checked={active}
							disabled={comingSoon}
							data-click={props['data-click'] || null}
							data-field-name={name}
						>
							<div className={styles.value}>
								<div className={clsx(styles.icon, { [styles.iconActive]: active })}>
									{icon && (
										<AsyncIcon src={icon} className={clsx(styles.iconCity, { [styles.disabled]: comingSoon })} />
									)}
								</div>
								<div
									className={clsx(styles.name, {
										[styles.comingSoon]: comingSoon,
									})}
								>
									{name}
									{comingSoon && <b>Coming Soon</b>}
								</div>
							</div>
						</SelectBox>
					</div>
				)
			})}
		</div>
	)
}
