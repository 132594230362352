import { useMount } from 'hooks/use-mount'
import { useNavigationEvents } from 'hooks/use-navigation-events'
import type { EventData } from 'utils/analytics'
import { emitAnalyticsEvent, getAnalyticsData } from 'utils/analytics'

function getTargetEl(evt: MouseEvent): HTMLElement | null {
	if (evt.target instanceof HTMLElement) {
		let target: HTMLElement = evt.target

		if (target.getAttribute('data-click')) {
			return target
		}

		while (target.parentElement !== null) {
			if (target.getAttribute('data-click')) {
				return target
			}

			target = target.parentElement
		}
	}

	return null
}

function handleAnalyticsClick(meta: EventData['meta'], fieldName?: string | null, action?: string | null) {
	const defaultParams: EventData['meta']['params'] = {
		...meta?.params,
		action: meta?.params?.action ?? 'click',
	}

	if (fieldName) {
		defaultParams.field_name = fieldName
	}

	if (action) {
		defaultParams.action = action
	}

	if (meta?.params?.eventType) {
		const { eventType, ...params } = meta.params
		emitAnalyticsEvent(meta.params?.eventType, {
			...defaultParams,
			name: meta?.name || '',
			params,
		})
		return
	}

	emitAnalyticsEvent({
		name: meta?.name || '',
		params: defaultParams,
	})
}

function handleClick(evt: MouseEvent) {
	const targetEl = getTargetEl(evt)

	if (targetEl === null) {
		return
	}

	const eventData = targetEl.getAttribute('data-click')
	const fieldName = targetEl.getAttribute('data-field-name')
	const action = targetEl.getAttribute('data-action')

	if (!eventData) {
		return
	}

	const parsedData = getAnalyticsData(eventData)

	if (parsedData) {
		if (Array.isArray(parsedData)) {
			for (const meta of parsedData) {
				handleAnalyticsClick(meta, fieldName, action)
			}
		} else {
			handleAnalyticsClick(parsedData, fieldName, action)
		}
	}
}

function getTargetValue(el: HTMLInputElement) {
	switch (el.type) {
		case 'checkbox':
			return el.checked
		case 'tel':
			return el.value.replace(/\D/g, '')
		default:
			return el.value
	}
}

function handleChange(evt: Event) {
	if (!(evt.target instanceof HTMLInputElement)) {
		return
	}

	const eventData = evt.target.getAttribute('data-change')

	if (!eventData) {
		return
	}

	const parsedData = getAnalyticsData(eventData)

	const value = getTargetValue(evt.target)

	if (parsedData) {
		if (Array.isArray(parsedData)) {
			for (const meta of parsedData) {
				emitAnalyticsEvent({
					name: meta?.name || '',
					params: { ...meta?.params, field_value: value },
				})
			}
		} else {
			emitAnalyticsEvent({
				name: parsedData?.name || '',
				params: { ...parsedData?.params, field_value: value },
			})
		}
	}
}

export function useAnalyticsEvents() {
	useNavigationEvents([() => null, handleRouteComplete])

	useMount(() => {
		emitAnalyticsEvent('page')
	})

	useMount(() => {
		document.addEventListener('click', handleClick, { passive: true })
		document.addEventListener('change', handleChange, { passive: true })

		return () => {
			document.removeEventListener('click', handleClick)
			document.removeEventListener('change', handleChange)
		}
	})

	function handleRouteComplete() {
		emitAnalyticsEvent('page')
	}
}
