import clsx from 'clsx'

import { HeaderContextProvider } from './Header.context'
import styles from './Header.module.css'

type Props = React.PropsWithChildren<{
	pathname?: string
	theme?: 'dark' | 'light' | 'cobalt'
}>

export function Header({ children, theme = 'dark', pathname = '' }: Props) {
	return (
		<HeaderContextProvider state={{ pathname }}>
			<header className={styles.root}>
				<nav className={clsx(styles.nav, styles[theme])}>{children}</nav>
			</header>
		</HeaderContextProvider>
	)
}
