import { useEffect } from 'react'

import { useNavigationEvents } from 'hooks/use-navigation-events'
import { extractCityFromPath } from 'utils/extract-city-from-path'

import { STORAGE_KEYS } from '../../libs/consts'

const excludePaths = new Set(['/users/login/callback'])

export function useNavigationChange() {
	const handleRouteChange = () => {
		const currentPath = window.location.pathname

		if (!excludePaths.has(currentPath)) {
			localStorage.setItem(STORAGE_KEYS.lastVisitedUrl, window.location.href)
		}

		const cityFromPath = extractCityFromPath(window.location.pathname)
		const cityFromLocalStorage = localStorage.getItem(STORAGE_KEYS.currentCity)

		if (!cityFromPath || cityFromPath === cityFromLocalStorage) return

		localStorage.setItem(STORAGE_KEYS.currentCity, cityFromPath)
	}

	useNavigationEvents([handleRouteChange])

	useEffect(() => {
		const currentPath = window.location.pathname

		if (!excludePaths.has(currentPath)) {
			localStorage.setItem(STORAGE_KEYS.lastVisitedUrl, window.location.href)
		}
	}, [])
}
