import isEqual from 'lodash/isEqual'
import type { GetServerSidePropsContext } from 'next'

import { useMount } from 'hooks/use-mount'
import { setCookie, getCookie } from 'infrastructure/cookies'
import { logError } from 'infrastructure/logger'
import { COOKIE_KEYS } from 'libs/consts'
import { Time } from 'libs/consts'

const COOKIE_MAX_AGE = Time.DAY * 14

export type UTMParams = {
	utm_source?: string | null
	utm_medium?: string | null
	utm_campaign?: string | null
	gclid?: string | null
	fbclid?: string | null
}

function getUTMParamsAsObject(href: string | null): UTMParams | null {
	if (!href) {
		return null
	}

	const urlParams = new URLSearchParams(href)
	const utm_source = urlParams.get('utm_source')
	const utm_medium = urlParams.get('utm_medium')
	const utm_campaign = urlParams.get('utm_campaign')
	const gclid = urlParams.get('gclid')
	const fbclid = urlParams.get('fbclid')

	if ([utm_source, utm_medium, utm_campaign, gclid, fbclid].every((param) => param === null)) {
		return null
	}

	return {
		utm_source,
		utm_medium,
		utm_campaign,
		gclid,
		fbclid,
	}
}

export function getLandingUrlFromCookie(ctx?: GetServerSidePropsContext | null) {
	const cookieValue = getCookie(COOKIE_KEYS.landingPageUrl, ctx)

	if (!cookieValue) {
		return null
	}

	return cookieValue
}

export function useUtmParams() {
	useMount(() => {
		try {
			const utmParams = getUTMParamsAsObject(window.location.search)

			if (!utmParams) {
				return
			}

			const landingUrlFromCookie = getLandingUrlFromCookie()
			const isUtmEqual = isEqual(getUTMParamsAsObject(landingUrlFromCookie), utmParams)

			if (isUtmEqual) {
				return
			}

			setCookie(COOKIE_KEYS.landingPageUrl, window.location.href, {
				maxAge: String(COOKIE_MAX_AGE),
				encode(value) {
					return JSON.stringify(value)
				},
			})
		} catch (err) {
			logError(err)
		}
	})
}
