export function isGoogleCrawler() {
	// This condition will work only on server
	if (typeof window === 'undefined') {
		return false
	}

	// This condition will work for google page speed insights and lighthouse
	// https://gist.github.com/vielhuber/8330860726b3573b8a66e5e16c7f89c3
	if (
		navigator.userAgent.match(
			/(Mozilla\/5\.0 \(Linux; Android 11; moto g power \(2022\)\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Chrome\/109\.0.0.0 Mobile Safari\/537\.36)|(Mozilla\/5\.0 \(Macintosh; Intel Mac OS X 10_15_7\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Chrome\/109\.0\.0\.0 Safari\/537\.36)|(Speed Insights)|(Chrome-Lighthouse)|(PSTS[\d\.]+)/
		)
	) {
		return true
	}

	return false
}
