import { createContext, useContext } from 'react'

import { SiteRoutes } from 'libs/routes'

export type HeaderContextState = {
	pathname: string
}

const HeaderContext = createContext({ pathname: SiteRoutes.ROOT() } as HeaderContextState)

type Props = React.PropsWithChildren<{
	state: HeaderContextState
}>

export function HeaderContextProvider({ children, state }: Props) {
	return <HeaderContext.Provider value={state}>{children}</HeaderContext.Provider>
}

export function useHeaderContext() {
	return useContext(HeaderContext)
}
