import { useRouter as useNextRouter } from 'next/router'
import { useCallback, useMemo } from 'react'

import type { UseRouterResult, TransitionFunction } from './types'
import { getQuery, getParams } from './utils'

export const useRouter = <
	P extends Record<string, string> = Record<string, string>,
	Q extends Record<string, string> = Record<string, string>
>(): UseRouterResult<P, Q> => {
	const router = useNextRouter()
	const hash = router.asPath.split('#')[1] ?? ''
	const query = useMemo(() => getQuery<Q>(router.asPath), [router.asPath])
	const params = useMemo(() => getParams<Q, P>(router.query, query), [query, router.query])
	const push = useCallback<TransitionFunction>(
		(url, { as, ...restOptions } = {}) => {
			if (!router.push) return Promise.resolve(true)

			return router.push(url, as, restOptions)
		},
		[router]
	)
	const replace = useCallback<TransitionFunction>(
		(url, { as, ...restOptions } = {}) => {
			if (!router.replace) return Promise.resolve(true)

			return router.replace(url, as, restOptions)
		},
		[router]
	)

	return useMemo(
		() => ({
			...router,
			push,
			replace,
			hash,
			params,
			query,
			pathname: router.asPath.split('?')[0],
		}),
		[hash, params, push, query, replace, router]
	)
}
