import { useCallback, useRef } from 'react'

import { AsyncIcon } from 'components/AsyncIcon'
import { Link } from 'components/Link'

import styles from './Banner.module.css'

type Props = {
	onClick?(): void
	onClose?(): void
	text: string
	url?: string
	linkText?: string
}

export function Banner({ onClick, onClose, text, url, linkText }: Props) {
	const closeButtonRoot = useRef<HTMLButtonElement>(null)
	const handleClick = useCallback<React.MouseEventHandler<HTMLDivElement | HTMLAnchorElement>>(
		(evt) => {
			const closeButtonElement = closeButtonRoot.current

			if (!closeButtonElement) {
				evt.preventDefault()

				return
			}

			if (closeButtonElement === evt.target || closeButtonElement.contains(evt.target as HTMLElement)) {
				evt.preventDefault()

				return
			}

			if (!url) {
				onClick?.()
			}
		},
		[url, onClick]
	)

	const handleClose = useCallback(() => {
		onClose?.()
	}, [onClose])

	const content = (
		<>
			<div className={styles.content}>{text}</div>
			<button className={styles.close} onClick={handleClose} ref={closeButtonRoot} type="button">
				<AsyncIcon src={require('assets/icons/cross.svg')} />
			</button>
		</>
	)

	if (url) {
		return (
			<Link external className={styles.root} href={url} title={linkText} onClick={handleClick}>
				{content}
			</Link>
		)
	}

	return (
		<div className={styles.root} onClick={handleClick}>
			{content}
		</div>
	)
}
