import clsx from 'clsx'

import { AsyncIcon } from 'components/AsyncIcon'
import { Link } from 'components/Link'
import type { City } from 'server/data/initial-data.types'
import { pluralize } from 'utils/pluralize'

import styles from './CityCard.module.css'

export type CityCardProps = (Pick<City, 'name' | 'numberOfResidences' | 'comingSoon'> & Partial<Pick<City, 'icon'>>) & {
	className?: string
	href: string
	titleComponent?: keyof JSX.IntrinsicElements
}

export const CityCard = ({
	className,
	icon,
	name,
	numberOfResidences,
	comingSoon,
	href,
	titleComponent: TitleComponent = 'p',
}: CityCardProps) => {
	return (
		<Link href={href} className={styles.link}>
			<div className={clsx(styles.root, comingSoon && styles.grayscale, className)}>
				<div className={styles.content}>
					<TitleComponent className={styles.title}>{name}</TitleComponent>
					{!comingSoon && numberOfResidences ? (
						<p className={styles.description}>
							{numberOfResidences} {pluralize('Listing', 's', numberOfResidences)}
						</p>
					) : null}
					{comingSoon && <p className={styles.description}>Coming Soon</p>}
				</div>
				{icon && (
					<div className={styles.logo}>
						<AsyncIcon src={icon} />
					</div>
				)}
			</div>
		</Link>
	)
}
