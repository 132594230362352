import clsx from 'clsx'
import { forwardRef, useMemo } from 'react'

import { AsyncIcon } from 'components/AsyncIcon'
import type { ToastOptionsWithProps } from 'features/Toast'
import { Toast } from 'features/Toast/Toast'

import styles from './Notification.module.css'

export type NotificationProps = React.HTMLAttributes<HTMLDivElement> & {
	kind?: 'success' | 'info' | 'failed'
	actionHref?: string
	actionText?: string
	onCloseClick?: React.MouseEventHandler<HTMLButtonElement>
	onActionClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
	children?: React.ReactText
}

const NotificationRender = forwardRef<HTMLDivElement, NotificationProps>(function Notification(
	{
		children,
		onCloseClick,
		onActionClick,
		actionHref,
		actionText = 'Action',
		kind = 'success',
		className,
		...restProps
	},
	ref
) {
	const actionElement = useMemo(() => {
		if (actionHref) {
			return (
				<a href={actionHref} onClick={onActionClick} className={styles.actionLink}>
					{actionText}
				</a>
			)
		}

		if (onActionClick) {
			return (
				<button onClick={onActionClick} className={styles.actionButton} type="button">
					{actionText}
				</button>
			)
		}

		return null
	}, [onActionClick, actionHref, actionText])

	return (
		<div {...restProps} className={clsx(styles.root, styles[kind], className)} ref={ref}>
			{kind === 'success' && <AsyncIcon className={styles.successIcon} src={require('assets/icons/check-mark.svg')} />}
			<div className={clsx(styles.content, !onCloseClick && styles.withCloseButton)}>
				<p className={styles.message}>{children}</p>
				{actionElement}
			</div>
			{onCloseClick && (
				<button className={styles.closeButton} onClick={onCloseClick} title="Close button" type="button">
					<AsyncIcon className={styles.closeIcon} src={require('assets/icons/cross.svg')} />
				</button>
			)}
		</div>
	)
})

// eslint-disable-next-line testing-library/render-result-naming-convention
export const BaseNotification = NotificationRender

export const Notification = (props: ToastOptionsWithProps) => <Toast {...props} component={NotificationRender} />
