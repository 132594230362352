import clsx from 'clsx'
import { Children, useEffect, useRef, useState } from 'react'

import { Link } from 'components/Link'
import { Portal } from 'components/Portal'
import { useMedia } from 'hooks/use-media'
import { useMount } from 'hooks/use-mount'
import { MEDIA_QUERY } from 'libs/consts'
import { lockScroll, unlockScroll } from 'utils/lock-scroll'

import styles from './Item.module.css'

type Props = React.PropsWithChildren<{
	className?: string
	href?: string
	icon?: React.ReactElement
	fullwidth?: boolean
	onClick?: React.MouseEventHandler<HTMLElement>
	dropdown?: null | (({ close }: { close?: () => void }) => React.ReactElement | null)
	dropdownPosition?: 'left' | 'right'
	applyActiveStyles?: boolean
}>

export function HeaderItem({
	children,
	href,
	className,
	fullwidth = false,
	dropdown: Dropdown = null,
	dropdownPosition = 'left',
}: Props) {
	const isMobile = useMedia([MEDIA_QUERY.smUp], [false], true)
	const portalRef = useRef<HTMLDivElement>(null)
	const rootRef = useRef<HTMLDivElement>(null)
	const [isOpened, setOpen] = useState(false)
	const itemProps: React.AllHTMLAttributes<HTMLElement> = {}
	const dropdownProps: React.AllHTMLAttributes<HTMLDivElement> = {}

	function handleSetOpen(isOpened: boolean) {
		setOpen(isOpened)
	}

	useEffect(() => {
		if (!isMobile) {
			return
		}

		if (isOpened) {
			lockScroll('fixed')
		} else {
			unlockScroll()
		}
	}, [isOpened, isMobile])

	useMount(() => {
		const rootElement = rootRef.current
		const portalElement = portalRef.current

		function handleClickOutside(evt: MouseEvent) {
			if (
				!rootElement ||
				rootElement === evt.target ||
				rootElement?.contains(evt.target as HTMLElement) ||
				portalElement?.contains(evt.target as HTMLElement)
			) {
				return
			}

			handleSetOpen(false)
		}

		document.addEventListener('click', handleClickOutside, true)

		return () => {
			document.removeEventListener('click', handleClickOutside, true)
		}
	})

	if (Dropdown) {
		itemProps.onClick = (evt) => {
			evt.preventDefault()
			handleSetOpen(!isOpened)
		}
		dropdownProps.onClick = (evt) => {
			const { dataset, tagName } = evt.target as HTMLElement

			if (tagName === 'A' || dataset?.close === 'click') {
				handleSetOpen(!isOpened)
			}
		}
	}

	const arrayChildren = Children.toArray(children)

	const ChildrenComponent = arrayChildren.length > 1 ? arrayChildren[isOpened ? 1 : 0] : arrayChildren[0]

	return (
		<div
			className={clsx(
				styles.root,
				// applyActiveStyles && isActive(href, pathname) && styles.active, // todo remove
				!fullwidth && styles.relativeEl,
				className
			)}
			ref={rootRef}
		>
			<div className={styles.wrapper}>
				{href ? (
					<Link href={href} className={styles.item} {...itemProps}>
						{children}
					</Link>
				) : (
					<div className={styles.item} {...itemProps}>
						{ChildrenComponent}
					</div>
				)}

				{Dropdown && (
					<>
						<Portal withoutPortal={!isOpened}>
							{isOpened && <div className={styles.background} onClick={() => handleSetOpen(false)} />}
						</Portal>
						<div
							ref={portalRef}
							{...dropdownProps}
							className={clsx(styles.dropdown, {
								[styles.dropdownShow]: isOpened,
								[styles.dropdownLeft]: dropdownPosition === 'left',
								[styles.dropdownRight]: dropdownPosition === 'right',
								[styles.fullwidth]: fullwidth,
							})}
						>
							{<Dropdown close={() => handleSetOpen(!isOpened)} />}
						</div>
					</>
				)}
			</div>
		</div>
	)
}
