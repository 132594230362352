import type { Reservation } from 'api/reservations.d'

export function shouldShowMyBookings(reservations: Reservation[] | null, hasReservationInProgress: boolean) {
	if (!reservations?.length) return false

	if (hasReservationInProgress && reservations.every(({ state, room }) => state === 'in_progress' && !room))
		return false

	return true
}
