import clsx from 'clsx'
import { useCallback, useEffect, useState } from 'react'

import { updateLead } from 'api/lead/client'
import { useAppContext } from 'context'
import { Banner as BannerFragment } from 'features/Container/fragments/Banner'
import { CityPicker } from 'fragments/CityPicker'
import { Filter } from 'fragments/Filter'
import { PickerFrame } from 'fragments/PickerFrame'
import { useUser } from 'hooks/use-user'
import { useRouter } from 'hooks/useRouter'
import { getCookie, setCookie } from 'infrastructure/cookies'
import { COOKIE_KEYS } from 'libs/consts'
import { SiteRoutes } from 'libs/routes'
import { setLead } from 'store/lead'

import styles from './Banner.module.css'

function getInitialIsShowed() {
	return Boolean(getCookie(COOKIE_KEYS.banner))
}

type Props = {
	url?: string
	title: string
	subtitle?: string
	linkText?: string
}

enum BannerStatus {
	'init',
	'visible',
	'hidden',
	'seen',
}

export function Banner({ url, title, subtitle, linkText }: Props) {
	const { cities } = useAppContext()
	const { user } = useUser()
	const { push } = useRouter()

	const [bannerStatus, setBannerStatus] = useState(BannerStatus.init)

	const handleHideBanner = useCallback(() => {
		setCookie(COOKIE_KEYS.banner, '1')
		setBannerStatus(BannerStatus.hidden)
	}, [])

	const handlePick = useCallback(
		(onClose: () => void) => async (city: string) => {
			if (user === null) {
				setLead(null, { city })
				handleHideBanner()
				push({ pathname: SiteRoutes.SIGNUP(), query: { next: `${SiteRoutes.JOURNEY()}?tourtype=vr` } })
			} else {
				await updateLead({ city })
				handleHideBanner()
				window.location.href = `${SiteRoutes.JOURNEY()}?tourtype=vr`
			}
			onClose()
		},
		[user, handleHideBanner, push]
	)

	const handleAnimationEnd = () => {
		if (bannerStatus === BannerStatus.hidden) {
			setBannerStatus(BannerStatus.seen)
		}
	}

	useEffect(() => {
		let timer: number | null = null
		const onLoad = () => {
			timer = window.setTimeout(() => {
				if (getInitialIsShowed()) {
					setBannerStatus(BannerStatus.seen)
				} else {
					setBannerStatus(BannerStatus.visible)
				}
			}, 100)
		}

		window.addEventListener('load', onLoad)

		return () => {
			if (timer) {
				window.clearTimeout(timer)
			}

			window.removeEventListener('load', onLoad)
		}
	}, [])

	if (bannerStatus === BannerStatus.init || bannerStatus === BannerStatus.seen) {
		return null
	}

	return (
		<div
			className={clsx(
				styles.root,
				bannerStatus === BannerStatus.visible && styles.show,
				bannerStatus === BannerStatus.hidden && styles.hide
			)}
			onAnimationEnd={handleAnimationEnd}
		>
			<Filter
				defaultWithoutPortal={false}
				popupPosition="center"
				selectorUnder
				selector={(_: boolean, handleToggleShow: () => void) => (
					<BannerFragment
						text={subtitle || title}
						url={url}
						linkText={linkText}
						onClose={handleHideBanner}
						onClick={handleToggleShow}
					/>
				)}
			>
				{(onClose: () => void) => (
					<PickerFrame>{cities.length && <CityPicker cities={cities} onPick={handlePick(onClose)} />}</PickerFrame>
				)}
			</Filter>
		</div>
	)
}
