import clsx from 'clsx'
import { useRouter } from 'next/navigation'
import type { MouseEvent } from 'react'

import type { Reservation } from 'api/reservations.d'
import { AsyncIcon } from 'components/AsyncIcon'
import { Link } from 'components/Link'
import { SiteRoutes } from 'libs/routes'
import { Button } from 'ui/components/Button'
import { Typography } from 'ui/components/Typography/v2'
import type { TypographyVariant } from 'ui/components/Typography/v2/types'
import type { Color } from 'ui/types'
import { formatToHumanize } from 'utils/date'

import styles from './ReservationItem.module.css'

type Props = Reservation & {
	loading: boolean
	onClick?: (id: number, state: Props['state'], isCurrent?: boolean) => void
	onClickLink?: (evt: MouseEvent<HTMLAnchorElement>) => void
	onClose?: () => void
}
type StateInfoEntry = {
	buttonText?: string
	infoText?: string
	icon?: string
	color?: Color[keyof Color]
}

const StateInfo: Record<Props['state'], StateInfoEntry> = {
	in_progress: {
		buttonText: 'Complete application',
		infoText: 'Application in progress',
		icon: 'alert',
		color: 'tangerine-800',
	},
	active: {
		buttonText: 'See home info',
	},
	archived: {},
	upcoming: {},
}

function InfoBlock({
	content,
	variant,
	color,
	className,
}: {
	content?: string
	variant: TypographyVariant
	color?: Color[keyof Color]
	className?: string
}) {
	return content ? (
		<Typography as="span" variant={variant} color={color} className={className}>
			{content}
		</Typography>
	) : null
}

export function ReservationItem({
	id,
	isCurrent,
	slug,
	room,
	movein,
	moveout,
	state,
	loading,
	main_application,
	open_review_details,
	onClick,
	onClickLink,
	onClose,
}: Props) {
	const { push } = useRouter()
	const { buttonText, infoText, icon, color } = StateInfo[state] || {}
	const archived = state === 'archived'
	const upcoming = state === 'upcoming'
	const active = state === 'active'
	const showMonthlyRent = active || upcoming || archived

	const handleOnClick = () => {
		onClick?.(id, state, isCurrent)
	}

	const handleClickMonthlyRent = async () => {
		const href = SiteRoutes.MONTHLY_PAYMENT({ journeyId: String(id) })
		push(href)
		onClose?.()
	}

	if (!id) return null
	const href = `${room?.url}?movein=${movein}&moveout=${moveout}&furnished=${room?.furnished ?? 'false'}`

	return (
		<div className={clsx(styles.root, { [styles.loading]: loading, [styles.disabled]: archived || upcoming })}>
			{(slug || room || (movein && moveout)) && (
				<div className={styles.content}>
					<div className={styles.slug}>
						<InfoBlock content={slug ? `${slug}   •   ` : ''} variant="p2-500" color="charcoal-100" />
						<InfoBlock content={room?.furnished ? 'Furnished' : 'Unfurnished'} variant="p2-500" color="charcoal-100" />
					</div>
					<div className={styles.info}>
						<Link
							className={styles.titleLink}
							href={href}
							onClick={(open_review_details && onClickLink) || undefined}
							target="_blank"
						>
							<InfoBlock content={room?.title} variant="h5-600" color="cobalt-800" />
							{main_application?.is_paid && <div className={styles.badge}>paid</div>}
						</Link>
						{movein && moveout && (
							<Typography as="span" variant="p2-500" color="charcoal-600">
								{formatToHumanize(movein, { format: 'MMMM do, yyyy' })} –{' '}
								{formatToHumanize(moveout, { format: 'MMMM do, yyyy' })}
							</Typography>
						)}
					</div>
				</div>
			)}
			{(infoText || buttonText || showMonthlyRent) && (
				<div className={styles.actions}>
					{infoText && (
						<div className={styles.progress}>
							<AsyncIcon className={styles.icon} src={require(`src/assets/icons/${icon}.svg`)} />
							<InfoBlock content={infoText} variant="p2-500" className={styles.status} color={color} />
						</div>
					)}

					<div className={styles.actionsRow}>
						{buttonText && (
							<Button size="M" onClick={handleOnClick}>
								{buttonText}
							</Button>
						)}
						{showMonthlyRent && (
							<Button size="M" variant="primary2" onClick={handleClickMonthlyRent}>
								Monthly Rent
							</Button>
						)}
					</div>
				</div>
			)}
		</div>
	)
}
