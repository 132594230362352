'use client'

import clsx from 'clsx'
import { usePathname } from 'next/navigation'
import { useSWRConfig } from 'swr'

import type { User } from 'api/user.d'
import { Footer } from 'components/Footer'
import { useAppContext } from 'context'
import { getSupportMethod, SUPPORT_METHOD } from 'features/App/get-support-method'
import { Header } from 'features/Header/Header.app'
import { usePhoneNumber } from 'hooks/use-phone-number'
import { useUser } from 'hooks/use-user'
import { FEATURES } from 'libs/features'

import styles from './Container.module.css'
import { PhoneButton } from './components/PhoneButton'
import { Banner } from './features/Banner'

type Props = React.PropsWithChildren<{
	showHeader?: 'all' | 'mobile' | 'desktop' | 'none'
	headerTheme?: 'light' | 'dark'
	whitePhoneButton?: boolean
	withOffset?: boolean
	stickyHeader?: boolean
	showFooter?: boolean
	showPhone?: boolean
	className?: string
	footerClassName?: string
	footerMarginTop?: boolean
	initialUserData?: User | null
}>

export function Container({
	children,
	showHeader = 'all',
	whitePhoneButton,
	withOffset,
	stickyHeader = false,
	showFooter = true,
	showPhone: showPhoneProp = true,
	className,
	footerClassName,
	footerMarginTop,
	initialUserData,
}: Props) {
	const pathname = usePathname()
	const { cache } = useSWRConfig()

	const layout = {
		showHeader,
		withOffset,
		stickyHeader,
		showFooter,
		showPhone: showPhoneProp,
		className,
		footerClassName,
		footerMarginTop,
	}
	const { user } = useUser(initialUserData)
	const supportMethod = getSupportMethod({ user, layout, pathname: String(pathname) })

	const showPhone = FEATURES.phoneWidget && supportMethod === SUPPORT_METHOD.phone
	// Clean cache for every request on server
	if (typeof window === 'undefined') {
		cache.clear()
	}

	const { cities, banners } = useAppContext()
	const phoneNumber = usePhoneNumber()
	const banner = banners?.main_page

	return (
		<div
			className={clsx(styles.root, className, {
				[styles.withOffset]: withOffset,
				[styles.withHeader]: showHeader,
			})}
		>
			<div
				className={clsx(styles.header, {
					[styles.showMobile]: showHeader === 'mobile',
					[styles.showDesktop]: showHeader === 'desktop',
					[styles.showAll]: showHeader === 'all',
					[styles.stickyHeader]: stickyHeader,
				})}
			>
				{banner && pathname === '/' && (
					<Banner url={banner.link} title={banner.title} linkText={banner.linkText} subtitle={banner.subtitle} />
				)}
				{showHeader !== 'none' && <Header />}
			</div>
			{children}
			{showPhone && <PhoneButton light={whitePhoneButton} phoneNumber={phoneNumber} />}
			{showFooter && (
				<div className={clsx(styles.footer)}>
					<Footer className={footerClassName} cities={cities} marginTop={footerMarginTop} />
				</div>
			)}
		</div>
	)
}
