import(/* webpackMode: "eager" */ "/work/src/assets/styles/styles.css");
import(/* webpackMode: "eager" */ "/work/src/context/context.tsx");
import(/* webpackMode: "eager" */ "/work/src/features/App/AbConfig/AbConfigHydration.tsx");
import(/* webpackMode: "eager" */ "/work/src/features/App/GoogleOneTap.tsx");
import(/* webpackMode: "eager" */ "/work/src/features/App/GTM/GTM.tsx");
import(/* webpackMode: "eager" */ "/work/src/features/Container/Container.app.tsx");
import(/* webpackMode: "eager" */ "/work/src/features/FrontappChat/FrontappChat.tsx");
import(/* webpackMode: "eager" */ "/work/src/features/Identify.tsx");
import(/* webpackMode: "eager" */ "/work/src/features/RootLayout/AnalyticsEvents.tsx");
import(/* webpackMode: "eager" */ "/work/src/features/RootLayout/NavigationChange.tsx");
import(/* webpackMode: "eager" */ "/work/src/features/RootLayout/NavigationProgress.tsx");
import(/* webpackMode: "eager" */ "/work/src/features/RootLayout/Resources.tsx");
import(/* webpackMode: "eager" */ "/work/src/features/RootLayout/ServiceWorker.tsx");
import(/* webpackMode: "eager" */ "/work/src/features/RootLayout/SWRProvider.tsx");
import(/* webpackMode: "eager" */ "/work/src/features/RootLayout/UtmParams.tsx")