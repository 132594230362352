import { logError } from 'infrastructure/logger'

import type { Identity } from './types'
import { loadScript } from './utils/load-script'

export class FrontappChatService {
	private initialized = false
	private failure = false

	private sdk: FrontChat | null = null
	private identity: Identity | null = null

	async register(identity?: Identity | null) {
		if (this.failure) {
			return
		}

		if (!identity) {
			this.destroy()

			return
		}

		if (!this.identity) {
			this.identity = identity

			await this.loadFrontChat()

			this.initFrontChat()

			return
		}

		if (this.identity.email !== identity.email) {
			this.identity = identity

			if (this.initialized) {
				this.switchIdentity()
			}

			return
		}
	}

	destroy() {
		if (this.failure || !this.initialized) {
			return
		}

		this.identity = null
		this.destroyFrontChat()
	}

	private async loadFrontChat() {
		if (this.sdk) {
			return
		}

		if (typeof window !== 'undefined' && window.FrontChat) {
			this.sdk = window.FrontChat

			return
		}

		try {
			await loadScript()

			if (typeof window !== 'undefined' && window.FrontChat) {
				this.sdk = window.FrontChat
			} else {
				throw new Error('Could not find FrontChat SDK.')
			}
		} catch (err) {
			this.failure = true
			logError(err)
		}
	}

	private initFrontChat() {
		if (!this.sdk || !this.identity) {
			return
		}

		try {
			this.sdk('init', {
				useDefaultLauncher: true,
				...this.identity,
			})

			this.initialized = true
		} catch (err) {
			this.failure = true
			logError(err)
		}
	}

	private destroyFrontChat() {
		this.initialized = false

		if (!this.sdk) {
			return
		}

		try {
			this.sdk('shutdown')
		} catch (err) {
			logError(err)
		}
	}

	private switchIdentity() {
		if (!this.sdk || !this.identity) {
			return
		}

		try {
			const { email, name, userHash } = this.identity

			this.sdk('identity', {
				email,
				name,
				userHash,
			})
		} catch (err) {
			logError(err)
		}
	}
}
