import clsx from 'clsx'

import { Link } from 'components/Link'
import { Typography } from 'ui/components/Typography'

import s from './NavigationList.module.css'
import type { NavigationListItem } from './types'

export type NavigationListProps = {
	className?: string
	title: string
	list: NavigationListItem[]
	listItemClassName?: string
}

export const NavigationList = ({ className, title, list, listItemClassName }: NavigationListProps) => (
	<div className={clsx(s.root, className)}>
		<Typography className={s.title} as="span" variant="h6">
			{title}
		</Typography>

		<ul className={s.list}>
			{list.map(({ href, text, ...restProps }) => (
				<li key={href} className={clsx(s.listItem, listItemClassName)}>
					<Link href={href} {...restProps}>
						<Typography variant="body" as="span">
							{text}
						</Typography>
					</Link>
				</li>
			))}
		</ul>
	</div>
)
