'use client'

import { memo } from 'react'

import { useGTM } from './useGTM'

export const GTM = memo(function Scripts() {
	useGTM()

	return null
})
