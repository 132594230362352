import { useCallback, useEffect, useRef, useState } from 'react'

import { BaseNotification } from 'components/Notification/Notification'

import styles from './Toast.module.css'
import type { ToastOptionsWithProps } from './toast.types'

export function Toast({
	kind = 'info',
	duration = 5000,
	children,
	requestClose = false,
	isClosable = true,
	onCloseComplete,
	id,
	onRequestRemove,
	component: Component = BaseNotification,
}: ToastOptionsWithProps) {
	const [delay, setDelay] = useState(duration)
	const [isRendered, setRender] = useState(true)
	const closedByUser = useRef(false)

	function onMouseEnter() {
		setDelay(null)
	}

	function onMouseLeave() {
		setDelay(duration)
	}

	const close = useCallback(() => {
		closedByUser.current = true
		setRender(false)
		if (!isRendered) {
			onRequestRemove()
		}
		onCloseComplete?.(closedByUser.current)
	}, [onCloseComplete, onRequestRemove, isRendered])

	useEffect(() => {
		if (requestClose) {
			setRender(false)
		}
	}, [requestClose])

	useEffect(() => {
		setDelay(duration)
	}, [duration])

	useEffect(() => {
		if (!delay) {
			return
		}

		const renderTimeoutId = setTimeout(close, delay)

		return () => {
			clearTimeout(renderTimeoutId)
		}
	}, [delay, close])

	if (!isRendered) {
		return null
	}

	return (
		<Component
			data-toast={id}
			key={id}
			className={styles.root}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onCloseClick={isClosable ? close : undefined}
			kind={kind}
		>
			{children}
		</Component>
	)
}
