import { usePathname } from 'next/navigation'
import nProgress from 'nprogress'
import { useRef, useCallback } from 'react'

import { useMount } from 'hooks/use-mount'
import { useNavigationEvents } from 'hooks/use-navigation-events'

function isEqualPaths(firstPathname: string, secondPathname: string) {
	return firstPathname === secondPathname
}

export function useNavigationProgress() {
	const pathname = usePathname()
	const lastPathnameRef = useRef(pathname)

	useMount(() => {
		nProgress.configure({ easing: 'ease', speed: 200, trickleSpeed: 80 })
	})

	const handleRouteChangeStart = useCallback((newPathname: string) => {
		if (lastPathnameRef.current && isEqualPaths(lastPathnameRef.current, newPathname)) {
			return
		}

		nProgress.start()
	}, [])

	const handleRouteChangeComplete = useCallback((newPathname: string) => {
		if (lastPathnameRef.current && isEqualPaths(lastPathnameRef.current, newPathname)) {
			return
		}

		lastPathnameRef.current = newPathname
		nProgress.done()
	}, [])

	const handleRouterError = useCallback(() => {
		nProgress.done()
	}, [])

	useNavigationEvents([handleRouteChangeStart, handleRouteChangeComplete, handleRouterError])
}
