import { useEffect } from 'react'

import type { User } from 'api/user.d'
import { emitAnalyticsEvent, saveAnalyticsParams } from 'utils/analytics'
import { formatDateToISO8601 } from 'utils/date'
import { filterEmptyProps } from 'utils/filter'

export function useIdentify(user: User | null): void {
	useEffect(() => {
		saveAnalyticsParams({
			userId: user ? String(user.id) : null,
			userEmail: user ? user.email : undefined,
			userPhoneNumber: user ? user.phoneNumber : undefined,
			userName: user ? `${user.firstName || ''} ${user.lastName || ''}` : undefined,
		})

		if (!user) {
			return
		}

		emitAnalyticsEvent('identify', {
			name: String(user.id),
			params: filterEmptyProps({
				user_id: user.id,
				name: `${user.firstName || ''} ${user.lastName || ''}`,
				email: user.email,
				phoneNumber: user.phoneNumber,
				createdAt: user.created ? formatDateToISO8601(user.created) : undefined,
			}),
		})
	}, [user])
}
