import { ExternalRoutes, SiteRoutes, Emails } from 'libs/routes'

import type { NavigationListItem } from './components/NavigationList'

export type CityLink = {
	text: string
	href: string
}

export type CitySlug = 'new-york-city-ny' | 'washington-dc' | 'boston-ma' | 'chicago' | 'other'

type SpecificCityLinksType = {
	[key in CitySlug]?: CityLink[]
}

export const OTHER_CITY_SLUG = 'other'

export const companyList: NavigationListItem[] = [
	{ text: 'About us', href: SiteRoutes.ABOUT_US() },
	{ text: 'How it works', href: SiteRoutes.HOW_IT_WORKS() },
	{ text: 'Careers', href: SiteRoutes.CAREERS() },
	{ text: 'Press', href: Emails.PRESS },
	{ text: 'Blog', href: ExternalRoutes.BLOG, external: true, rel: 'noopener noreferrer' },
	{ text: 'Sitemap', href: SiteRoutes.SITE_MAP() },
	{ text: 'Reviews', external: true, href: ExternalRoutes.REVIEW_BLOG },
]

export const policyList: NavigationListItem[] = [
	{ text: 'Terms of Use', href: SiteRoutes.TERMS_OF_USE() },
	{ text: 'Cookie Policy', href: SiteRoutes.COOKIE_POLICY() },
	{ text: 'Privacy Policy', href: SiteRoutes.PRIVACY_POLICY() },
	{ text: 'Rent Calculator', external: true, href: ExternalRoutes.RENT_CALCULATOR },
	{ text: 'Roommate Rent Splitter', external: true, href: ExternalRoutes.ROOMMATE_RENT_SPLITTER },
	{ text: 'Roommate Agreement', external: true, href: ExternalRoutes.ROOMMATE_AGREEMENT },
]

export const housingList: NavigationListItem[] = [
	{ text: 'Military Housing', href: SiteRoutes.MILITARY_HOUSING() },
	{ text: 'Travel Nurse Housing', href: SiteRoutes.TRAVEL_NURSE_HOUSING() },
	{ text: 'Student Housing', href: SiteRoutes.FOR_STUDENTS() },
	{ text: 'Refer a Friend', href: SiteRoutes.REFERRER() },
	{ text: 'Subletspots', href: ExternalRoutes.SUBLETSPOTS, external: true },
	{ text: 'FAQ', href: SiteRoutes.FAQ() },
]

export const specificCityLinks: SpecificCityLinksType = {
	'new-york-city-ny': [
		{ text: 'Rooms', href: SiteRoutes.CATALOG_WITH_BEDROOMS({ city: 'new-york-city-ny' }) },
		{ text: 'Furnished Apartments', href: SiteRoutes.CATALOG_WITH_APARTMENTS({ city: 'new-york-city-ny' }) },
		{ text: 'Studios', href: SiteRoutes.CATALOG_WITH_STUDIOS({ city: 'new-york-city-ny' }) },
		{ text: 'Manhattan', href: `${SiteRoutes.CATALOG_WITH_CITY({ city: 'new-york-city-ny' })}?areas=manhattan` },
		{ text: 'Brooklyn', href: `${SiteRoutes.CATALOG_WITH_CITY({ city: 'new-york-city-ny' })}?areas=brooklyn` },
		{ text: 'Queens', href: `${SiteRoutes.CATALOG_WITH_CITY({ city: 'new-york-city-ny' })}?areas=queens` },
		{ text: 'NY Student Housing', href: SiteRoutes.STUDENT_CATALOG_WITH_CITY({ city: 'new-york-city-ny' }) },
	],
	'washington-dc': [
		{ text: 'Rooms', href: SiteRoutes.CATALOG_WITH_BEDROOMS({ city: 'washington-dc' }) },
		{ text: 'Apartments', href: SiteRoutes.CATALOG_WITH_APARTMENTS({ city: 'washington-dc' }) },
		{ text: 'Studios', href: SiteRoutes.CATALOG_WITH_STUDIOS({ city: 'washington-dc' }) },
		{ text: 'DC Student Housing', href: SiteRoutes.STUDENT_CATALOG_WITH_CITY({ city: 'washington-dc' }) },
	],
	'boston-ma': [
		{ text: 'Rooms', href: SiteRoutes.CATALOG_WITH_BEDROOMS({ city: 'boston-ma' }) },
		{ text: 'Apartments', href: SiteRoutes.CATALOG_WITH_APARTMENTS({ city: 'boston-ma' }) },
		{ text: 'Studios', href: SiteRoutes.CATALOG_WITH_STUDIOS({ city: 'boston-ma' }) },
		{ text: 'Boston Student Housing', href: SiteRoutes.STUDENT_CATALOG_WITH_CITY({ city: 'boston-ma' }) },
	],
	'chicago': [
		{ text: 'Rooms', href: SiteRoutes.CATALOG_WITH_BEDROOMS({ city: 'chicago' }) },
		{ text: 'Apartments', href: SiteRoutes.CATALOG_WITH_APARTMENTS({ city: 'chicago' }) },
		{ text: 'Studios', href: SiteRoutes.CATALOG_WITH_STUDIOS({ city: 'chicago' }) },
		{ text: 'Chicago Student Housing', href: SiteRoutes.STUDENT_CATALOG_WITH_CITY({ city: 'chicago' }) },
	],
}

export const citySlugToTitleMapping: Record<CitySlug, string> = {
	'new-york-city-ny': 'New York, NY',
	'washington-dc': 'Washington, D.C.',
	'boston-ma': 'Boston, MA',
	'chicago': 'Chicago, IL',
	[OTHER_CITY_SLUG]: 'Other cities',
}
