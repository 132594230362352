import { EMAIL, PHONE_NUMBER } from 'libs/consts'
import { Emails, ExternalRoutes, PhoneNumbers } from 'libs/routes'

import facebook from './icons/facebook.svg'
import instagram from './icons/instagram.svg'
import linkedin from './icons/linkedin.svg'
import tiktok from './icons/tiktok.svg'

type ContactItem = {
	text: string[]
	href?: string
}

type SocialItem = {
	href: string
	icon: string
}

export const contactsList: ContactItem[] = [
	{ href: PhoneNumbers.SUPPORT, text: ['Get in touch with us:', PHONE_NUMBER.support, '7.30am to 8pm CT'] },
	{ href: Emails.SUPPORT, text: ['or email us:', EMAIL.support] },
	{ text: ['June Homes US, Inc.'] },
	{ text: ['California DRE # 02161034'] },
]

export const socialList: SocialItem[] = [
	{ href: ExternalRoutes.SOCIAL_FACEBOOK, icon: facebook },
	{ href: ExternalRoutes.SOCIAL_INSTAGRAM, icon: instagram },
	{ href: ExternalRoutes.SOCIAL_TIKTOK, icon: tiktok },
	{ href: ExternalRoutes.SOCIAL_LINKEDIN, icon: linkedin },
]
