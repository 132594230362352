import { Toast } from './Toast'
import { toast } from './toast.class'
import type { ToastProps } from './toast.types'

export function useToast(options: ToastProps) {
	return toast.notify(Toast, options)
}

export function useFailtureToast(options?: ToastProps) {
	return useToast({
		children: 'Something went wrong 🙁 Please, try again',
		kind: 'failed',
		...options,
	})
}
