import type { User } from 'api/user.d'
import { useFrontappChat } from 'hooks/use-frontapp-chat'

type Props = {
	user?: User | null
}

export function RenderChat({ user }: Props) {
	useFrontappChat(user)

	return null
}
