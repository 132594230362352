'use client'

import type { User } from 'api/user.d'

import { useGoogleOneTap } from './use-google-one-tap'
import { useLoadGoogleOneTap } from './use-load-google-one-tap'

export type Props = {
	initialUserData?: User | null
}

export function GoogleOneTap({ initialUserData }: Props) {
	useLoadGoogleOneTap()
	useGoogleOneTap(initialUserData)

	return null
}
