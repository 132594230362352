import styles from './MobileMenu.module.css'

type Props = React.PropsWithChildren & {
	active?: boolean
}

export function MobileMenu({ active = true, children }: Props) {
	// eslint-disable-next-line react/jsx-no-useless-fragment
	if (!active) return <>{children}</>

	return (
		<div className={styles.root}>
			<div className={styles.burgerMenu}>{children}</div>
		</div>
	)
}
