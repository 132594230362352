import clsx from 'clsx'

import { NoSSR } from 'components/NoSSR'
import { SiteRoutes } from 'libs/routes'
import type { City } from 'server/data/initial-data.types'

import styles from './Cities.module.css'
import { CityCard } from './components/CityCard'

type Props = {
	cities: City[]
}

export function Cities({ cities }: Props) {
	return (
		<NoSSR>
			<ul className={styles.list}>
				{cities?.map(({ name, slug, icon, numberOfResidences, comingSoon }) => (
					<li key={slug} className={clsx(styles.listItem, comingSoon && styles.grayscale)}>
						<CityCard
							href={SiteRoutes.CATALOG_WITH_CITY({ city: slug })}
							className={styles.cityCard}
							name={name}
							icon={icon}
							numberOfResidences={numberOfResidences}
							comingSoon={comingSoon}
						/>
					</li>
				))}
			</ul>
		</NoSSR>
	)
}
