import { AsyncIcon } from 'components/AsyncIcon'
import { contactsList, socialList } from 'components/Footer/components/Contacts/lists'
import { Link } from 'components/Link'
import { Typography } from 'ui/components/Typography'

import s from './Contacts.module.css'

export const Contacts = () => (
	<div className={s.contacts}>
		<ul className={s.socialList}>
			{socialList.map(({ href, icon }) => (
				<li key={href}>
					<Link external href={href}>
						<AsyncIcon src={icon} />
					</Link>
				</li>
			))}
		</ul>
		<ul className={s.contactsList}>
			{contactsList.map(({ href, text }) => {
				const [label, link, desc] = text

				return (
					<Typography key={label} as="li" variant="h4">
						{label}{' '}
						{href && (
							<a className={s.link} href={href}>
								{link}
							</a>
						)}
						{desc && (
							<>
								<br /> {desc}
							</>
						)}
					</Typography>
				)
			})}
		</ul>
	</div>
)
