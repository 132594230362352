import { useEffect } from 'react'

import type { User } from 'api/user.d'

import { FrontappChatService } from './frontapp-chat-service'
import { formatUser } from './utils/format-user'

const frontappChat = new FrontappChatService()

export function useFrontappChat(user?: User | null) {
	useEffect(() => {
		frontappChat.register(formatUser(user))

		return () => {
			frontappChat.destroy()
		}
	}, [user])
}
