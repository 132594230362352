import { useEffect, useRef } from 'react'

import { GTM_CONTAINER_ID } from 'infrastructure/google-tag-manager'
import { isGoogleCrawler } from 'utils/is-google-crawler'

const SCRIPT_ID = 'google-tag-manager'
const SCRIPT_BODY = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_CONTAINER_ID}');`

function injectScript() {
	const script = document.createElement('script')
	script.async = true
	script.id = SCRIPT_ID
	script.onerror = function (error) {
		throw error
	}
	script.innerHTML = SCRIPT_BODY
	document.head.appendChild(script)
}

export const useGTM = () => {
	const isInjected = useRef<boolean>(false)

	useEffect(() => {
		if (GTM_CONTAINER_ID && !isGoogleCrawler() && !isInjected.current && !document.getElementById(SCRIPT_ID)) {
			injectScript()
			isInjected.current = true
		}
	}, [])
}
