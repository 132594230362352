import clsx from 'clsx'

import { ArrowRound } from 'components/ArrowRound'

import styles from './Selector.module.css'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
	label?: string
	value?: string
	highlighted?: boolean
	left?: boolean
	invalid?: boolean
	active?: boolean
	showArrow?: boolean
}

export function Selector({ label, value, highlighted, active, invalid, showArrow = false, left, ...props }: Props) {
	return (
		<button
			type="button"
			className={clsx(styles.root, {
				[styles.selected]: Boolean(value),
				[styles.highlighted]: highlighted && Boolean(value),
				[styles.left]: left,
				[styles.invalid]: invalid,
				[styles.active]: active,
			})}
			{...props}
		>
			<div className={styles.label}>{label}</div>
			{value && <div className={styles.value}>{value}</div>}
			{showArrow && (
				<div className={styles.arrow}>
					<ArrowRound size="13px" />
				</div>
			)}
		</button>
	)
}
