import { useEffect, useState } from 'react'

import { api } from 'infrastructure/legacy-api-transport'

const PARAM = 'utm_mark'
const DEFAULT_PHONE_NUMBER = '+1 (888) 604-6697'
let cachedPhoneNumber: string

function getEndpoint() {
	const params = new URLSearchParams(window.location.search)
	const mark = params.get(PARAM)

	if (!mark) {
		return null
	}

	return `/get-phone-number/${mark}`
}

export function usePhoneNumber() {
	const [phoneNumber, setPhoneNumber] = useState<string>()

	useEffect(() => {
		const endpoint = getEndpoint()

		// don't make request when have already cached phone number
		if (cachedPhoneNumber) {
			setPhoneNumber(cachedPhoneNumber)

			return
		}

		if (endpoint === null) {
			cachedPhoneNumber = DEFAULT_PHONE_NUMBER
			setPhoneNumber(cachedPhoneNumber)

			return
		}

		api
			.get<{ phone?: string }>(endpoint)
			.then(({ data }) => {
				cachedPhoneNumber = data.phone || DEFAULT_PHONE_NUMBER
				setPhoneNumber(cachedPhoneNumber)
			})
			.catch(() => {
				cachedPhoneNumber = DEFAULT_PHONE_NUMBER
				setPhoneNumber(cachedPhoneNumber)
			})
	}, [])

	return phoneNumber
}

export function resetCache() {
	cachedPhoneNumber = ''
}
