import clsx from 'clsx'
import { useState, useEffect, useRef } from 'react'

import { Portal } from 'components/Portal'
import type { PopupPosition } from 'components/SelectPopup'
import { SelectPopup } from 'components/SelectPopup'
import { Selector } from 'components/Selector'
import { useMedia } from 'hooks/use-media'
import type { DataAnalytics } from 'utils/analytics'
import { lockScroll, unlockScroll } from 'utils/lock-scroll'

import styles from './Filter.module.css'

type BaseProps = {
	value?: string | undefined
	isVisible?: boolean
	labelAtLeft?: boolean
	selectorUnder?: boolean
	popupPosition?: PopupPosition
	defaultWithoutPortal?: boolean
	disableLockScroll?: boolean
	borderSize?: number
	showArrow?: boolean
	children(onClose: () => void): React.ReactNode
	onClose?(): void
	overlayClassName?: string
	popupClassName?: string
	selectorClassName?: string
}

export type FilterProps = BaseProps &
	(
		| {
				label: string
				selector?: never
		  }
		| {
				selector?(isShowed: boolean, handleToggleShow: () => void): React.ReactNode
				label?: never
		  }
	) & { 'data-test-id'?: string } & DataAnalytics

export function Filter({
	label,
	value,
	children,
	labelAtLeft,
	selector,
	selectorUnder,
	popupPosition,
	disableLockScroll = false,
	isVisible = false,
	showArrow = false,
	borderSize,
	onClose,
	'data-click': dataClick,
	'data-test-id': dataTestId,
	overlayClassName,
	popupClassName,
	selectorClassName,
}: FilterProps) {
	const withoutPortal = useMedia(['(min-width: 767px)'], [true], false)
	const wasShowedRef = useRef(false)
	const [isShowed, setShow] = useState(false)

	useEffect(() => {
		setShow(isVisible)
	}, [isVisible])

	function handleToggleShow() {
		setShow((state) => !state)
		if (isShowed) {
			onClose?.()
		}
	}

	useEffect(() => {
		if (isShowed) {
			wasShowedRef.current = isShowed
		}
	}, [isShowed])

	useEffect(() => {
		setShow(false)
	}, [withoutPortal])

	useEffect(() => {
		if (disableLockScroll) {
			return
		}

		if (!withoutPortal && isShowed) {
			lockScroll()
		}

		return () => {
			unlockScroll()
		}
	}, [isShowed, withoutPortal, disableLockScroll])

	return (
		<div className={styles.root} data-test-id="filterSelect">
			<div
				className={clsx(
					styles.selector,
					{
						[styles.selectorActive]: isShowed,
						[styles.selectorUnder]: selectorUnder,
					},
					selectorClassName
				)}
			>
				{selector ? (
					selector(isShowed, handleToggleShow)
				) : (
					<Selector
						showArrow={showArrow}
						label={label}
						value={value}
						onClick={handleToggleShow}
						active={isShowed}
						left={labelAtLeft}
						data-click={dataClick || null}
						data-test-id={dataTestId || null}
					/>
				)}
			</div>
			{(wasShowedRef.current || isShowed) && (
				<Portal withoutPortal={withoutPortal}>
					<SelectPopup
						className={popupClassName}
						overlayClassName={overlayClassName}
						show={isShowed}
						onClickClose={handleToggleShow}
						position={popupPosition}
						borderSize={borderSize}
					>
						{children(handleToggleShow)}
					</SelectPopup>
				</Portal>
			)}
		</div>
	)
}
