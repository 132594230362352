import styles from './SelectBox.module.css'

type Props = Omit<React.AllHTMLAttributes<HTMLInputElement>, 'className'> & {
	as?: React.ElementType
}

export function SelectBox({ children, as: Component = 'input', ...inputProps }: Props) {
	if (Component === 'input') {
		inputProps.type = inputProps.type || 'checkbox'
	}

	return (
		<label className={styles.root}>
			<Component className={styles.input} {...inputProps} />
			<span className={styles.checkmark}>{children}</span>
		</label>
	)
}
