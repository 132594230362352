'use client'

import clsx from 'clsx'
import { useRouter } from 'next/navigation'
import { useEffect, useState, useMemo } from 'react'
import type { MouseEvent } from 'react'
import useSWR, { mutate } from 'swr'

import { setJourneyState } from 'api/journey/client'
import type { Reservation } from 'api/reservations.d'
import { ReservationItem } from 'components/ReservationItem'
import { toast, failtureToast } from 'features/Toast'
import { createBrowserProxyApiTransport } from 'infrastructure/legacy-api-transport'
import { api } from 'infrastructure/legacy-api-transport'
import { logError } from 'infrastructure/logger'
import { SiteRoutes } from 'libs/routes'
import { Typography } from 'ui/components/Typography/v2'

import styles from './ReservationsList.module.css'
import { segregateReservations } from './ReservationsList.utils'

type Props = {
	onSetCurrent?(): void
	onSetReservations?(reservations: Reservation[]): void
}

export function ReservationsList({ onSetCurrent, onSetReservations }: Props) {
	const { push } = useRouter()
	const [isLoading, setLoading] = useState<boolean | number>(false)
	const { data: reservations, mutate: revalidateReservations } = useSWR<Reservation[]>('/journey/reservations', {
		revalidateOnFocus: false,
		revalidateOnReconnect: false,
		dedupingInterval: 15000,
	})

	useEffect(() => {
		if (!reservations?.length) {
			return
		}
		onSetReservations?.(reservations)
	}, [reservations, onSetReservations])

	async function handleClickLink(evt: MouseEvent<HTMLAnchorElement>) {
		evt.preventDefault()
		const apiClient = createBrowserProxyApiTransport()
		await setJourneyState.call(apiClient, 'short_list_selection')
		push(`${SiteRoutes.JOURNEY()}`)
		onSetCurrent?.()
	}

	async function handleSetCurrent(id: number, state: string, isCurrent?: boolean) {
		if (!id || !reservations) {
			return
		}

		if (state === 'archived' || state === 'upcoming') {
			return
		}

		if (state === 'active') {
			push(SiteRoutes.MEMBER_PORTAL())
			return
		}

		push(`${SiteRoutes.MEMBER_PORTAL()}${window.location.search}`)
		if (isCurrent) {
			onSetCurrent?.()
			return
		}

		try {
			setLoading(id)
			await api.post(`/journey/reservations/set-current`, { id })

			await Promise.all([mutate('/journey'), revalidateReservations()])

			onSetCurrent?.()
			const [{ title }] = reservations.filter((r) => r.id === id)

			toast({
				children: `${title} has been chosen`,
				kind: 'success',
			})
		} catch (err) {
			logError(err)
			failtureToast()
		} finally {
			setLoading(false)
		}
	}

	const segregatedReservations = useMemo(() => {
		return segregateReservations(reservations || [])
	}, [reservations])

	if (!reservations || reservations.length === 0) {
		return null
	}

	return (
		<div className={styles.wrapper}>
			<div className={clsx(styles.root, { [styles.loading]: isLoading })}>
				{segregatedReservations.map(
					({ title, reservations }) =>
						reservations.length > 0 && (
							<div key={title} className={styles.sections}>
								<Typography as="span" variant="p1-500" color="charcoal-200">
									{title}
								</Typography>
								<ul className={styles.list}>
									{reservations.map((r) => (
										<li key={r.id} className={styles.item}>
											<ReservationItem
												{...r}
												loading={r.id === isLoading}
												onClick={handleSetCurrent}
												onClickLink={handleClickLink}
												onClose={onSetCurrent}
											/>
										</li>
									))}
								</ul>
							</div>
						)
				)}
			</div>
		</div>
	)
}
