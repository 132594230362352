import type { Reservation } from 'api/reservations.d'

export const SectionMap = {
	active: 'Active',
	in_progress: 'In progress',
	upcoming: 'Upcoming',
	archived: 'Archive',
}

export function segregateReservations(reservations: Reservation[]) {
	const reservationMap: Record<string, Reservation[]> = Object.keys(SectionMap).reduce((acc, key) => {
		acc[key] = []
		return acc
	}, {} as Record<string, Reservation[]>)

	reservations
		.filter((reservation) => reservation.room)
		.forEach((reservation) => {
			reservationMap[reservation.state]?.push(reservation)
		})

	return Object.entries(SectionMap).map(([state, title]) => ({
		title,
		reservations: reservationMap[state],
	}))
}
