import { Component } from 'react'

import type { ToastId, ToastMessage, ToastOptionsWithMessage, ToastProps } from './toast.types'

export interface Methods {
	notify: (message: ToastMessage, options: ToastProps) => ToastId
	closeAll: () => void
	close: (id: ToastId) => void
	isActive: (id: ToastId) => boolean
}

type CreateToastOptions = ToastProps & ToastOptionsWithMessage

type Props = {
	notify: (methods: Methods) => void
}

type State = { items: CreateToastOptions[] }

export class ToastPortal extends Component<Props, State> {
	static counter = 0

	state: State = {
		items: [],
	}

	constructor(props: Props) {
		super(props)

		const methods = {
			notify: this.notify,
			closeAll: this.closeAll,
			close: this.closeToast,
			isActive: this.isVisible,
		}

		props.notify(methods)
	}

	notify = (message: ToastMessage, options: ToastProps) => {
		const toast = this.createToast(message, options)
		const { id } = toast

		this.setState((prevState: State): State => {
			return {
				items: [toast, ...prevState.items],
			}
		})

		return id
	}

	closeAll = () => {
		this.state.items.forEach((toast) => {
			this.closeToast(toast.id)
		})
	}

	createToast = (message: ToastMessage, options: ToastProps): CreateToastOptions => {
		const id = ++ToastPortal.counter

		return {
			...options,
			id,
			message,
			onRequestRemove: () => this.removeToast(String(id)),
		}
	}

	closeToast = (id: ToastId) => {
		this.setState((prevState: State): State => {
			return {
				items: prevState.items.map((n) => ({ ...n, requestClose: n.id === id })),
			}
		})
	}

	removeToast = (id: ToastId) => {
		this.setState((prevState: State): State => {
			const nextState = prevState.items.filter((n) => n.id !== id)

			return {
				items: nextState,
			}
		})
	}

	isVisible = (id: ToastId) => {
		return this.state.items.some((n) => n.id === id)
	}

	render() {
		return this.state.items.map(({ message: Message, ...toast }) => <Message key={toast.id} {...toast} />)
	}
}
