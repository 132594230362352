let isRequested = false

export function loadScript() {
	if (isRequested) {
		return
	}

	isRequested = true

	if (typeof window.__frontCmdQueue === 'undefined') {
		window.__frontCmdQueue = []
	}

	return new Promise((resolve, reject) => {
		const script = document.createElement('script')

		script.async = true
		script.src = 'https://chat-assets.frontapp.com/v1/chat.bundle.js'
		script.onload = () => {
			resolve(true)
		}
		script.onerror = () => {
			reject(new Error("Frontapp chat script didn't load correctly."))
		}

		document.body.appendChild(script)
	})
}
