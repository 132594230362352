import { useEffect, useRef } from 'react'

import { isGoogleCrawler } from 'utils/is-google-crawler'

const SCRIPT_ID = 'gsi-client'

function injectScript() {
	const script = document.createElement('script')
	script.async = true
	script.defer = true
	script.id = SCRIPT_ID
	script.onerror = function (error) {
		throw error
	}
	script.src = `https://accounts.google.com/gsi/client`
	script.type = 'text/javascript'
	document.body.appendChild(script)
}

export const useLoadGoogleOneTap = () => {
	const isInjected = useRef<boolean>(false)

	useEffect(() => {
		if (!isGoogleCrawler() && !isInjected.current && !document.getElementById(SCRIPT_ID)) {
			injectScript()
			isInjected.current = true
		}
	}, [])
}
