import arrowIcon from 'assets/icons/arrow.svg'

import { AsyncIcon } from 'components/AsyncIcon'

import styles from './ArrowRound.module.css'

type Props = {
	size?: string | number
	rotate?: string | number
	background?: string
}
export function ArrowRound({ size, background, rotate = 0 }: Props) {
	return (
		<AsyncIcon
			src={arrowIcon}
			className={styles.root}
			style={{
				'--icon-size': size,
				'--icon-background': background,
				'--icon-rotate': `${rotate}deg`,
			}}
		/>
	)
}
