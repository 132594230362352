import { useEffect, useRef } from 'react'
import { Workbox } from 'workbox-window'
import type { WorkboxLifecycleWaitingEvent } from 'workbox-window'

const UNREGISTER_SW = process.env.NEXT_PUBLIC_UNREGISTER_SW === 'true'
const ENABLE_SW = false

export function useServiceWorker() {
	const shouldRegister = useRef(true)
	useEffect(() => {
		if (ENABLE_SW && 'serviceWorker' in navigator && shouldRegister.current) {
			const wb = new Workbox('/sw.js')

			// TODO: create dialog to update sw
			const showSkipWaitingPrompt = async (_?: WorkboxLifecycleWaitingEvent) => {
				wb.addEventListener('controlling', () => {
					window.location.reload()
				})

				const updateAccepted = true

				if (updateAccepted) {
					wb.messageSkipWaiting()
				}
			}

			// https://developer.chrome.com/docs/workbox/handling-service-worker-updates/
			wb.addEventListener('waiting', (event) => {
				showSkipWaitingPrompt(event)
			})

			wb.register().then((registration) => {
				if (registration && UNREGISTER_SW) {
					registration.unregister().then((result) => {
						if (result) {
							// eslint-disable-next-line no-console
							console.log('sw unregister is successful')
						}
					})
				}
			})

			shouldRegister.current = false
		}

		// unregister sw from old users
		if (!ENABLE_SW) {
			navigator?.serviceWorker?.getRegistrations().then((registrations) => {
				for (const registration of registrations) {
					registration.unregister()
				}
			})
		}
	})
	return null
}
