import clsx from 'clsx'

import styles from './PickerFrame.module.css'

type Props = React.PropsWithChildren<{
	title?: string
	subtitle?: string
	changed?: boolean
	footer?: React.ReactNode
	scrollable?: boolean
	scrollContainerClassName?: string
}>

export function PickerFrame({ title, subtitle, scrollable, footer = null, children, scrollContainerClassName }: Props) {
	return (
		<article className={clsx(styles.root, scrollable && styles.rootScrollable)}>
			{title || subtitle ? (
				<header className={styles.header}>
					<h5>{title}</h5>
					{subtitle && <p className={styles.subtitle}>{subtitle}</p>}
				</header>
			) : null}
			{scrollable ? (
				<section className={clsx(styles.scrollContainer, scrollContainerClassName)}>
					<div className={styles.content}>{children}</div>
				</section>
			) : (
				<section className={styles.content}>{children}</section>
			)}
			{footer}
		</article>
	)
}
