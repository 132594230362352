import type { User } from 'api/user.d'

import type { Identity } from '../types'
import { isBlocked } from './is-blocked'

export function formatUser(user?: User | null): Identity | null {
	if (isBlocked() || !user || !user.email || !user.frontappHash || !user.showChat) {
		return null
	}

	const { email, firstName, lastName, frontappHash: userHash } = user
	const name = firstName && lastName ? `${firstName} ${lastName}` : (firstName as string)

	return {
		name,
		email,
		userHash,
		chatId: process.env.NEXT_PUBLIC_FRONTAPP_CHAT_ID as string,
	}
}
