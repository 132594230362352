import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'

import type { User } from 'api/user.d'
import { SiteRoutes } from 'libs/routes'

const SKIP_GOOGLE_AUTH_POPUP_ROUTES = [SiteRoutes.SIGNIN(), SiteRoutes.SIGNUP()]

export function useGoogleIdentifyPrompt(user: User | null | undefined, isUserValidating: boolean): void {
	const [promptStatus, setPromptStatus] = useState()
	const pathname = usePathname()

	const isRouteToSkip = SKIP_GOOGLE_AUTH_POPUP_ROUTES.includes(String(pathname))

	useEffect(() => {
		if (!isUserValidating && !user && !promptStatus && !isRouteToSkip) {
			window?.google?.accounts?.id?.prompt((notification) => {
				if (notification.getMomentType() === 'display') {
					setPromptStatus(notification.i)
				}
			})
		}
	}, [user, isUserValidating, promptStatus, isRouteToSkip])
}
