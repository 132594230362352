'use client'

import type { User } from 'api/user.d'
import { useIdentify } from 'hooks/use-identify'
import { useUser } from 'hooks/use-user'

export type Props = {
	initialUserData?: User | null
}

export function Identify({ initialUserData }: Props) {
	const { user } = useUser(initialUserData)
	useIdentify(user ?? null)

	return null
}
