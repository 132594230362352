import type { GetServerSidePropsContext } from 'next'

import { formatLeadForServer } from 'api/lead/formatters'
import type { Lead } from 'api/lead/types'
import type { ServerSmartForm } from 'api/server-api'
import { getCookie, removeCookie, setCookie } from 'infrastructure/cookies'
import { logError } from 'infrastructure/logger'
import { COOKIE_KEYS } from 'libs/consts'
import { getAnalyticsParams } from 'utils/analytics'

import { getCityFromRequest, getCityFromLocalStorage } from './lead.utils'

export function getLead(ctx: GetServerSidePropsContext | null, smartform?: boolean): Lead {
	const data = getCookie(COOKIE_KEYS.smartform, ctx)

	if (!data) {
		return {}
	}

	const currentPathCity = ctx ? getCityFromRequest(ctx.req) : getCityFromLocalStorage()

	try {
		const lead = JSON.parse(data)
		const leadWithCity = { ...lead, city: lead.city ?? currentPathCity ?? null }

		return Object.assign(leadWithCity, getAnalyticsParams(), { fromSmartform: smartform })
	} catch (err) {
		removeCookie(COOKIE_KEYS.smartform, ctx)
		logError(err)

		return {}
	}
}

export function getLeadForServer(ctx: GetServerSidePropsContext | null, smartform?: boolean): ServerSmartForm {
	const lead = getLead(ctx, smartform)
	const leadForServer = { ...formatLeadForServer(lead), ...getAnalyticsParams() }

	// "home_type" field type guard
	// https://junehomes.atlassian.net/browse/WEB-1750
	if (leadForServer.home_type && !Array.isArray(leadForServer.home_type)) {
		// @ts-expect-error We can't consider this as optional without deep refactoring
		delete leadForServer.home_type
	}

	// @ts-expect-error We can't consider this as optional without deep refactoring
	return leadForServer
}

export function setLead(ctx: GetServerSidePropsContext | null, lead: Lead) {
	setCookie(
		COOKIE_KEYS.smartform,
		{ ...getLead(ctx), ...lead },
		{
			ctx,
			encode(value) {
				return JSON.stringify({
					...value,
					firstName: value.firstName && encodeURIComponent(value.firstName),
					lastName: value.lastName && encodeURIComponent(value.lastName),
				})
			},
		}
	)
}
