import clsx from 'clsx'
import { useEffect, useState, useRef } from 'react'

import { checkIsOutsideClick } from 'hooks/use-click-outside'
import { lockScroll } from 'utils/lock-scroll'

import styles from './SelectPopup.module.css'

export type PopupPosition =
	| 'combodown'
	| 'rightdown'
	| 'leftdown'
	| 'leftup'
	| 'leftdowncenter'
	| 'leftcenter'
	| 'center'
	| 'leftupdown'
	| 'screencenter'

type Props = React.PropsWithChildren<
	{
		className?: string
		position?: PopupPosition
		show?: boolean
		borderSize?: number
		closeOutside?: boolean
		popupzoverlay?: number
		lockScrollType?: 'fixed'
		overlayClassName?: string
		onClickClose(): void
	} & { 'data-test-id'?: string }
>

export function SelectPopup({
	'className': popupClassName,
	children,
	show = false,
	closeOutside = true,
	onClickClose,
	position,
	borderSize,
	popupzoverlay,
	lockScrollType,
	overlayClassName,
	'data-test-id': dataTestId,
}: Props) {
	const popupRef = useRef<HTMLDivElement>(null)
	const [isRendered, setRender] = useState(show)
	const [isShowed, setShow] = useState(false)

	useEffect(() => {
		const timeoutId = setTimeout(() => setShow(show), 20)

		if (show) {
			setRender(true)
		}

		if (lockScrollType) {
			lockScroll(lockScrollType)
		}

		return () => clearTimeout(timeoutId)
	}, [show, lockScrollType])

	useEffect(() => {
		const popupElement = popupRef.current

		function handleTransitionend() {
			setRender(isShowed)
		}

		popupElement?.addEventListener('transitionend', handleTransitionend)

		return () => {
			popupElement?.removeEventListener('transitionend', handleTransitionend)
		}
	}, [isShowed])

	useEffect(() => {
		if (!isShowed || !closeOutside) {
			return
		}

		const popup = popupRef.current

		function handleClickOutside(evt: MouseEvent) {
			if (!popup || !checkIsOutsideClick(popup, evt)) {
				return
			}

			onClickClose()
		}

		document.addEventListener('click', handleClickOutside, true)

		return () => {
			document.removeEventListener('click', handleClickOutside, true)
		}
	}, [isShowed, closeOutside, onClickClose])

	if (!isRendered) {
		return null
	}

	return (
		<>
			<div
				className={clsx(styles.overlay, { [styles.hidden]: !isShowed }, overlayClassName)}
				style={{ ...(popupzoverlay ? { '--popup-z-overlay': popupzoverlay } : {}) }}
				key="overlay"
			/>
			<div
				data-test-id={dataTestId}
				ref={popupRef}
				className={clsx(styles.popup, position && styles[position], !isShowed && styles.hidden, popupClassName)}
				style={{ ...(borderSize ? { '--select-popup-border': `${borderSize}px` } : {}) }}
				key="popup"
			>
				{children}
			</div>
		</>
	)
}
