'use client'

import { Router } from 'next/router'
import { useEffect } from 'react'

type UseNavigationEventsProps = Array<(url: string) => void>

export function useNavigationEvents([start, complete, error]: UseNavigationEventsProps) {
	useEffect(() => {
		start && Router.events.on('routeChangeStart', start)
		complete && Router.events.on('routeChangeComplete', complete)
		error && Router.events.on('routeChangeError', error)

		return () => {
			start && Router.events.off('routeChangeStart', start)
			complete && Router.events.off('routeChangeComplete', complete)
			error && Router.events.off('routeChangeError', error)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
}
