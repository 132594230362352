'use client'

import ReactDOM from 'react-dom'

const imagesResource = process.env.NEXT_PUBLIC_IMAGES_RESOURCE || 'https://storage.googleapis.com/junehomes'
const resourceList = [
	imagesResource,
	'https://www.google-analytics.com',
	'https://www.google.com',
	'https://www.googleadservices.com',
	'https://www.googletagmanager.com',
	'https://maps.googleapis.com',
	'https://fonts.gstatic.com',
	'https://maps.gstatic.com',
	'https://bid.g.doubleclick.net',
	'https://stats.g.doubleclick.net',
	'https://googleads.g.doubleclick.net',
	'https://connect.facebook.net',
	'https://www.facebook.com',
	'https://fast.a.klaviyo.com',
	'https://static.klaviyo.com',
]

export function Resources() {
	ReactDOM.preload(require('assets/fonts/AvenirNextLTPro-Bold.woff2'), {
		as: 'font',
		crossOrigin: 'anonymous',
	})
	ReactDOM.preload(require('assets/fonts/AvenirNextLTPro-Medium.woff2'), {
		as: 'font',
		crossOrigin: 'anonymous',
	})
	resourceList.map((resource) => ReactDOM.preconnect(resource))

	return null
}
